/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Each version of Quill is built and ready to use from a variety of sources, including ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/quill"
  }, "NPM"), " or its ", React.createElement(_components.a, {
    href: "/docs/download/"
  }, "CDN"), ". However there may be use cases where you would like to build Quill from source, as part of your application's build pipeline. If this desire has not occurred to you, don't sweat it! Using pre-built versions is the easiest way to use Quill."), "\n", React.createElement(_components.p, null, "Quill is built using ", React.createElement(_components.a, {
    href: "https://webpack.js.org/concepts/"
  }, "Webpack"), " and this guide is mostly targeted towards Webpack users. However some principles may translate to other build environments."), "\n", React.createElement(_components.p, null, "A minimal working example of everything covered in this guide can be found at ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/webpack-example/"
  }, "quill-webpack-example"), "."), "\n", React.createElement(_components.h3, null, "Webpack"), "\n", React.createElement(_components.p, null, "You will need to add Webpack and appropriate loaders as development dependencies to your app. The Typescript compiler is necessary if you want to build Parchment from source as well."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Quill source code - ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/babel-core"
  }, React.createElement(_components.code, null, "babel-core")), ", ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/babel-loader"
  }, React.createElement(_components.code, null, "babel-loader")), ", ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/babel-preset-es2015"
  }, React.createElement(_components.code, null, "babel-preset-es2015"))), "\n", React.createElement(_components.li, null, "Parchment source code - ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/ts-loader"
  }, React.createElement(_components.code, null, "ts-loader")), ", ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/typescript"
  }, React.createElement(_components.code, null, "typescript"))), "\n", React.createElement(_components.li, null, "SVG icons - ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/html-loader"
  }, React.createElement(_components.code, null, "html-loader"))), "\n"), "\n", React.createElement(_components.p, null, "Your Webpack configuration file will also need to alias Quill and Parchment to point to their respective entry source files. Without this, Webpack will use the pre-built files included in NPM, instead of building from source."), "\n", React.createElement(_components.h3, null, "Entry"), "\n", React.createElement(_components.p, null, "Quill is distributed with builds ", React.createElement(_components.code, null, "quill.js"), " and ", React.createElement(_components.code, null, "quill.core.js"), ". The purpose of the entry files for both builds, ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill/blob/master/quill.js"
  }, "quill.js"), " and ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill/blob/master/core.js"
  }, "core.js"), ", is to import and register necessary dependencies. You will likely want a similar entry point in your application that includes only the formats, modules, or themes that you use."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Quill from 'quill/core';\n\nimport Toolbar from 'quill/modules/toolbar';\nimport Snow from 'quill/themes/snow';\n\nimport Bold from 'quill/formats/bold';\nimport Italic from 'quill/formats/italic';\nimport Header from 'quill/formats/header';\n\n\nQuill.register({\n  'modules/toolbar': Toolbar,\n  'themes/snow': Snow,\n  'formats/bold': Bold,\n  'formats/italic': Italic,\n  'formats/header': Header\n});\n\n\nexport default Quill;\n")), "\n", React.createElement(_components.h3, null, "Stylesheets"), "\n", React.createElement(_components.p, null, "There is not as much to benefit from building from source in the realm of stylesheets, since rules can be so easily overwritten. However, ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/css-loader"
  }, React.createElement(_components.code, null, "css-loader")), "'s tilde prefix may be useful to include Quill styles in a your application css file."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "@import \"~quill/dist/quill.core.css\"\n\n// Rest of your application CSS\n")), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.p, null, "Take a look at ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/webpack-example/"
  }, "quill-webpack-example"), " for a minimal working example."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
